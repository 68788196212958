import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/languages',
    name: 'listLanguage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "listLanguage" */ '../views/Languages/ListView.vue')
  },
  {
    path: '/languages/create',
    name: 'createLanguage',
    component:  () => import(/* webpackChunkName: "createLanguage" */ '../views/Languages/CreateView.vue')
  },
  {
    path: '/languages/:id/edit',
    name: 'editLanguage',
    component: () => import(/* webpackChunkName: "editLanguage" */ '../views/Languages/EditView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/authors',
    name: 'listAuthor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "listAuthor" */ '../views/Authors/ListView.vue')
  },
  {
    path: '/authors/create',
    name: 'createAuthor',
    component:  () => import(/* webpackChunkName: "createAuthor" */ '../views/Authors/CreateView.vue')
  },
  {
    path: '/authors/:id/edit',
    name: 'editAuthor',
    component: () => import(/* webpackChunkName: "editAuthor" */ '../views/Authors/EditView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/albums',
    name: 'listAlbum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "listAlbum" */ '../views/Albums/ListView.vue')
  },
  {
    path: '/albums/create',
    name: 'createAlbum',
    component:  () => import(/* webpackChunkName: "createAlbum" */ '../views/Albums/CreateView.vue')
  },
  {
    path: '/albums/:id/edit',
    name: 'editAlbum',
    component: () => import(/* webpackChunkName: "editAlbum" */ '../views/Albums/EditView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/medias',
    name: 'listMedia',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "listMedia" */ '../views/Medias/ListView.vue')
  },
  {
    path: '/medias/create',
    name: 'createMedia',
    component:  () => import(/* webpackChunkName: "createMedia" */ '../views/Medias/CreateView.vue')
  },
  {
    path: '/medias/:id/edit',
    name: 'editMedia',
    component: () => import(/* webpackChunkName: "editMedia" */ '../views/Medias/EditView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/phrases/:id',
    name: 'listPhrase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "listPhrase" */ '../views/Phrases/ListView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/phrases/:id/create',
    name: 'createPhrase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "createPhrase" */ '../views/Phrases/CreateView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/phrases/:id/edit',
    name: 'editPhrase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "editPhrase" */ '../views/Phrases/EditView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/phrases/:id/sync',
    name: 'syncPhrase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "syncPhrase" */ '../views/Phrases/SyncView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/translations/:id',
    name: 'listTranslation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "listTranslation" */ '../views/Translations/ListView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/translations/:id/create',
    name: 'createTranslation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "createTranslation" */ '../views/Translations/CreateView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/translations/:id/edit',
    name: 'editTranslation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "editTranslation" */ '../views/Translations/EditView.vue'),
    props: true // Passa o parâmetro da URL como props para o componente
  },
  {
    path: '/crawler',
    name: 'indexCrawler',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "indexCrawler" */ '../views/Crawler/IndexView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
