<template>
  <nav>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/languages">Languages</router-link> |
    <router-link to="/authors">Authors</router-link> |
    <router-link to="/albums">Albums</router-link> | 
    <router-link to="/medias">Medias</router-link> |
    <router-link to="/crawler">Crawler</router-link> | -->
 
    <!-- <router-link to="/mediaPhrases">Phrases</router-link> -->
  </nav>
  <router-view/>
</template>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
